
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-content label {
    display: block;
    margin: 10px 0;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
  }
  