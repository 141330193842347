

.modal-content {
  border-top: 0px;
}

.class-summary-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.event-type-container {
  width: 50%;
  display: flex;
}

.edit-button {
  background-color: #ffc107;
  border-color: #e0a800;
  box-shadow: #ffc107 0 10px 20px -10px;
}

.edit-button:hover {
  background-color: #e0a800;
}

.event-list {
  width: 100%;
}

.clickable {
  margin: 7px 0 7px 0;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  width: 100%;
}

.clickable:hover {
  background-color: #e6e6e6;
}

.event-list-header {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.class-summary-container .event-type-container .btn-sm {
  font-size: 0.9rem;
  padding: 0.25rem 0.5rem;
  margin-left: 10px;
}