

.generate-button {
  background-color: #28a745;
  border-color: #218838;
  box-shadow: #28a745 0 10px 20px -10px;
}

.generate-button:hover {
  background-color: #218838;
}

.modal-header .class-name {
  font-size: 0.9em;
  color: #6c757d;
  margin-top: 5px;
}

.edit-button {
  background-color: #ffc107;
  border-color: #e0a800;
  box-shadow: #ffc107 0 10px 20px -10px;
}

.edit-button:hover {
  background-color: #e0a800;
}

.continue-button {
  background-color: #007bff;
  border-color: #0056b3;
  box-shadow: #007bff 0 10px 20px -10px;
}

.continue-button:hover {
  background-color: #0056b3;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
.small-back-button {
  font-size: 1rem;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  margin-left: 10px;
}

.button-disabled {
  background-color: #d3d3d3;
  color: #a0a0a0;
  border: 1px solid #a0a0a0;
  box-shadow: none;
  cursor: not-allowed;
}

.button-disabled:hover {
  background-color: #d3d3d3;
  color: #a0a0a0;
}

.delete-confirmation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.delete-confirmation p {
  margin-bottom: 20px;
}

.button.confirm-button {
  background-color: #ff5e5e;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
}

.button.cancel-button {
  background-color: #5e5df0;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.katex-html {
  display: none !important;
}
