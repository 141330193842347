.home-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
}

.date-control {
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
}

.date-control label {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-right: 10px;
}

.date-control input[type="date"] {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
  margin-right: 10px;
}

.week-button {
  padding: 8px 12px;
  margin: 0 5px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.week-button:hover {
  background-color: #0056b3;
}

.home-class-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.home-class-list > div {
  width: 450px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.home-class-list h3 {
  margin-bottom: 10px;
  font-size: 20px;
  color: #007bff;
  border-bottom: 2px solid #007bff;
  padding-bottom: 5px;
}

.home-class-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: #555;
}

.home-class-list li {
  padding: 8px 0;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.home-class-list li:hover {
  background-color: #f0f0f0;
}

.home-class-list li:last-child {
  border-bottom: none;
}

.home-class-list li::before {
  content: "•";
  color: #007bff;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.no-events {
  text-align: center;
  color: #555;
  font-size: 18px;
  margin-top: 20px;
}

.no-events p {
  padding: 15px;
  border-radius: 8px;
  display: inline-block;
}
