#auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
}

#auth-header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.auth-toggle-button {
  background: none;
  border: none;
  font-size: 18px;
  padding: 10px 20px;
  cursor: pointer;
  transition: color 0.3s ease, border-bottom 0.3s ease;
}

.auth-toggle-button.active {
  color: #3498db;
  font-weight: bold;
  border-bottom: 2px solid #3498db;
}

.auth-toggle-button:not(.active):hover {
  color: #7f8c8d;
}

#auth-forms {
  width: 300px;
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

#auth-forms form div {
  margin-bottom: 15px;
}

#auth-forms label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #2c3e50;
}

#auth-forms input {
  width: 100%;
  padding: 10px;
  border: 1px solid #bdc3c7;
  border-radius: 5px;
  font-size: 16px;
}

#auth-forms button {
  width: 100%;
  padding: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#auth-forms button:hover {
  background-color: #2980b9;
}

#auth-forms p {
  color: red;
  margin-top: 10px;
}
