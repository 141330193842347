.classes-container {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 100vh;
}

h1 {
  color: #333;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
}

.class-item-container {
  margin-bottom: 20px;
}

.class-item {
  border-radius: 8x;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px 15px 0 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  min-height: 122px;
}

.class-item h3 {
  margin-bottom: 10px;
  font-size: 20px;
  color: #007bff;
  border-bottom: 2px solid #007bff;
  padding-bottom: 5px;
}

.edit-class-label {
  margin-top: 10px;
  color: #333;
  font-weight: bold;
  cursor: pointer;
}

.edit-class-label:hover {
  text-decoration: underline;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
