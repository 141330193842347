header {
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  height: auto;
}

.user-button {
  width: 50px;
  height: 50px;
  border: solid 1px black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: lightgrey;
  cursor: pointer;
  position: relative;
}

.navbar {
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  position: relative;
}

.page-links {
  margin: 0px 10px 50px 10px;
  color: black;
  text-decoration: none;
}

.page-links:hover {
  cursor: pointer;
  text-decoration: underline;
}

.fade-in {
  opacity: 1;
  transform: translateY(0);
}

#user-pop-up {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 1;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  font-size: 0.6em;
  margin: 10px 0px 10px 0px;
  border-bottom: 1px solid grey;
}

#logout-button {
  padding: 10px 15px;
  width: 100%;
  cursor: pointer;
  border: none;
}

#logout-button:hover {
  background-color: #cc0000;
}

.dropdown-item {
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  color: black;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.settings-item {
  font-size: 0.9em;
}

.settings-item:hover {
  background-color: transparent;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin-top: 10px; /* Adjust as needed */
}

.username-item:hover {
  background-color: transparent;
  cursor: default;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.active-link {
  text-decoration: underline;
}
