.dropzone {
    border: 2px dashed #cccccc;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    background-color: #f9f9f9;
    transition: background-color 0.2s ease;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .upload-success {
    color: green;
    font-weight: bold;
  }
  