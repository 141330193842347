.my-calendar-container {
  display: flex;
  position: relative;
  height: 100vh;
}

.calendar {
  flex-grow: 1;
  transition: width 0.3s, margin-right 0.3s;
  max-width: 90%;
  margin: 20px 10px 0 10px;
}

.with-sidebar {
  width: 100%;
}

.class-list {
  width: 300px;
  padding: 20px;
  border-left: 1px solid #ddd;
  height: 100%;
  overflow-y: auto;
}

.class-list h3 {
  margin-bottom: 10px;
}

.class-list ul {
  list-style: none;
  padding: 0;
}

.class-list li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
}

.class-button:hover {
  background-color: #e6e6e6;
}


.class-color-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  min-width: 20px;
}

.toggle-filters {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
  padding: 5px 5px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  z-index: 996;
}

.toggle-sidebar-button:hover {
  background-color: #0056b3;
}
.filter-list {
  margin-top: 10px;
}

.filter-list li {
  display: flex;
  align-items: center;
}