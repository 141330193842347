.practice-container {
  padding: 20px;
  border-radius: 10px;
}

.practice-event {
  margin: 0 10px 20px 10px;
  padding: 15px;
  border: 1px solid #007bff;
  border-radius: 10px;
  background-color: #fff;
}

.practice-event h3 {
  color: #007bff;
  font-weight: bold;
}

.practice-event p {
  margin: 5px 0;
}

.btn-primary {
  background-color: #007bff;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.practice-event-container {
  margin-bottom: 20px;
}

.practice-container > h2,
.practice-container > p {
  margin: 0 0 20px 10px;
}

